<template>
  <SingleChatWrapper>
    <sdCards>
      <template #title>
        <router-link to="/app/chat/group" class="back-display" style="float: left"
          ><a-avatar
            src="https://cdn4.iconfinder.com/data/icons/pointer-1/128/_-Arrow_back_circled_direction_left_prev_previous-128.png"
          />
        </router-link>
        <span style="font-size: 14px !important; float: left"
          >{{ treatmentName }} - {{ docName ? 'Dr.' + docName : 'Pending Assignment' }}</span
        >

        <!-- <div class="group-chat-header"  style="width:70%"> -->
        <!-- <div style="float:left;"> -->

        <!-- </div> -->

        <!-- <strong>{{ treatment.name }}</strong> -->

        <!-- </div> -->
      </template>

      <!-- <p v-if="!loader && noMessage">
        There’s been no chats yet from your physician or you! Feel free to write a message for your medical professional
        to see when they check your case, or simply wait for them to message you!
      </p> -->
      <ul v-if="!loader && noMessage" class="atbd-chatbox" id="chatbox">
        <!-- <div v-for="chat in messages" :key="chat[1].id" class="atbd-chatbox__single"> -->
        <div class="atbd-chatbox__single left">
          <div :style="{ overflow: 'hidden' }">
            <div class="left-b">
              <div class="atbd-chatbox__content" id="chatter">
                <div class="atbd-chatbox__contentInner d-flex">
                  <img :src="require(`@/static/img/avatar/chat-auth.png`)" alt="" />
                  <div class="atbd-chatbox__message">
                    <MessageList class="message-box"
                      >Hello , <br />
                      Thank you for choosing Better Doctor! We’re excited to be assisting you with finding the best
                      treatment option for you! This is where your Healthcare Professional will message you currently
                      you are pending assignment to one of our healthcare professionals! We will keep you updated here
                      and through email and text
                    </MessageList>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ul>
      <ul v-if="!loader" class="atbd-chatbox" id="chatbox">
        <!-- <div v-for="chat in messages" :key="chat[1].id" class="atbd-chatbox__single"> -->
        <div
          v-for="chat in messages"
          v-on="paramTrigger"
          :key="chat[1].id"
          class="atbd-chatbox__single"
          :class="chat[1].admin == '1' || chat[1].doc == '1' ? 'left' : 'right'"
        >
          <div :key="id" :style="{ overflow: 'hidden' }">
            <div :class="chat[1].admin == '1' || chat[1].doc == '1' ? 'left-b' : 'right'">
              <div class="atbd-chatbox__content" id="chatter">
                <sdHeading v-if="chat[1].admin == '1' || chat[1].doc == '1'" as="h5" class="atbd-chatbox__name">
                  <!-- {{ chat[1].admin === '1' ? 'Admin' : null }}
                  {{ chat[1].doc === '1' ? 'Doctor' : null }} -->
                  <span>
                    <!-- {{ chat[1].timestamp }} -->
                  </span>
                </sdHeading>

                <div v-if="chat[1].admin == '1' || chat[1].doc == '1'" class="atbd-chatbox__contentInner d-flex">
                  <img
                    v-if="chat[1].admin == '1' || chat[1].doc == '1'"
                    :src="require(`@/static/img/avatar/chat-auth.png`)"
                    alt=""
                  />
                  <div class="atbd-chatbox__message">
                    <MessageList class="message-box">{{ chat[1].note }}</MessageList>
                  </div>
                </div>

                <div v-else class="atbd-chatbox__contentInner d-flex">
                  <div v-if="chat[1].pat == '1'" class="atbd-chatbox__message">
                    <MessageList class="message-box">{{ chat[1].note }}</MessageList>
                  </div>
                </div>
                <span v-if="chat[1].doc !== '1'" class="tstamp">
                  {{ chat[1].timestamp !== 'undefined' ? moment(chat[1].timestamp).format('hh:mm A') : '' }}
                </span>
                <span v-else class="tstamp2">
                  {{ chat[1].timestamp }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </ul>
      <div v-else>
        <Main>
          <div class="loaderstyle">
            <div class="loader"></div>
          </div>
        </Main>
      </div>
      <Footer>
        <form @submit:prevent="handleForm">
          <div :class="`chatbox-reply-form d-flex ${fileList.length && 'hasImage'} ${fileList2.length && 'hasFile'}`">
            <div class="chatbox-reply-input">
              <input placeholder="Type your message..." name="chat" id="chat" v-model="chatMessage" />
            </div>
            <div class="chatbox-reply-action d-flex">
              <sdButton @click="handleForm" type="primary" class="btn-send">
                <sdFeatherIcons type="send" size="18" />
                <!-- <a-spin size="large"  class="spin2" /> -->
              </sdButton>
            </div>
          </div>
        </form>
      </Footer>
    </sdCards>
  </SingleChatWrapper>
</template>
<script>
import moment from 'moment';
import EmojiPicker from '@/components/utilities/Emoji.vue';
import { SingleChatWrapper, MessageList, BackShadowEmoji, Footer } from '../style';
import { useStore } from 'vuex';
import { computed, onMounted, ref } from 'vue';
import { message } from 'ant-design-vue';
import { useRoute } from 'vue-router';
import axios from 'axios';
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';

const SingleGroupChat = {
  name: 'SingleGroupChat',
  components: { SingleChatWrapper, MessageList, BackShadowEmoji, Footer, EmojiPicker, PerfectScrollbar },
  props: ['id', 'name'],
  data() {
    return {
      chatMessage: '',
      messages: [],
      treatment: {},
      isSent: false,
      loader: true,
      noMessage: false,
      docName: null,
      treatmentName: this.$route.params.name,
    };
  },
  computed: {
    paramTrigger: function () {
      console.log('computed');

      this.getMessages();
      return this.id;
    },
    // triggerMessage: function () {
    //       // `this` points to the vm instance
    //       console.log('computed')
    //       console.log(this.messages)

    //       this.getMessages()
    //       return this.messages
    //     }
  },
  updated() {
    console.log('updated');
    this.getMessages();
  },
  methods: {
    moveBottom() {
      // content.scrollTop = content.scrollHeight;
      console.log('content');
    },
    async getMessages() {
      // let message = []
      // window.location.reload()
      // alert('sdsd')
      this.loader = true;
      this.treatment = '';
      this.messages = [];
      await axios.post('get-messages.php',{id: this.id}).then(res => {
      // await axios.get('https://api.getbetterdoctor.com/api/message/' + this.id).then((res) => {
        console.log('getmessage');
        console.log('getMessages');
        this.loader = false;
        let response = res.data.messages;
        console.log(response);
        console.log('doc');
        if (response.message === 'No Message') {
          this.noMessage = true;
          this.docName = '';
        } else {
          this.noMessage = false;
          this.docName = res.data.messages.data.doc_name;
        }
        this.treatmentName = res.data.treatment.name;
        this.messages = Object.entries(response.data);
        this.messages.reverse().splice(0, 2);
        this.treatment = res.data.treatment;
        console.log(this.messages);

        console.log('getMessages');
        console.log(response.data);

        // console.log(response);
      });
      this.$nextTick(() => {
        var myDiv = document.getElementById('chatbox');
        myDiv.scrollTop = myDiv.scrollHeight;
      })
    },
    async handleForm(e) {
      let message = this.chatMessage;
      this.chatMessage = '';

      this.isSent = true;
      e.preventDefault();
      console.log(this.chatMessage);
      let vm = this;
      await axios
        .post('post-messages.php', {
          casenum: this.id,
          message: message,
        })
        .then((res) => {
          const response = res.data;
          if (response.status == true) {
            this.getMessages();
            this.chatMessage = '';
            console.log('successfully sent message');
          }
          if (response.status == false) {
            console.log("message couldn't be sent");
          }
          this.isSent = false;
        });
    },
  },
  async mounted() {
    // window.location.reload()
    // this.$router.go()

    await this.getMessages();
  },
  async setup() {
    // window.location.reload()

    const { state, dispatch } = useStore();
    const { params } = useRoute();
    const rtl = computed(() => state.ChangeLayoutMode.rtlData);
    const chatData = computed(() => state.chat.groupData.data);
    const left = computed(() => (!rtl.value ? 'left' : 'right'));

    const me = ref('woadud@gmail.com');
    const singleContent = computed(() => (chatData.value[0] ? chatData.value[0].content : []));
    const name = computed(() => chatData.value[0] && chatData.value[0].groupName);

    const inputValue = ref('');
    const fileList = ref([]);
    const fileList2 = ref([]);
    // let message = ref([]);
    let message = computed([]);

    // // let message = []
    // await axios.get('https://127.0.0.1:8259/api/message/07022112460815373').then((res) => {
    //   let response = res.data;
    //   message = response.data;
    //   console.log(message);
    // });

    // console.log(singleContent)

    const handleChange = (e) => {
      inputValue.value = e.target.value;
    };

    const handleSubmit = (e) => {
      e.preventDefault();

      console.log(inputValue.value);

      axios
        .post('post-messages.php', {
          casenum: '07022112460815373',
          message: inputValue.value,
        })
        .then((res) => {
          const response = res.data;
          if (response.status == true) {
            this.getMessage();
            console.log('successfully sent message');
          }
          if (response.status == false) {
            console.log("message couldn't be sent");
          }
        });

      // const pushcontent = {
      //   content: inputValue,
      //   time: new Date().getTime(),
      //   seen: false,
      //   reaction: false,
      //   email: me,
      // };
      // dispatch('updateGroupChat', { id: params.id, pushcontent });
      // singleContent.value = [...singleContent.value, pushcontent];
      inputValue.value = '';
    };

    onMounted(() => dispatch('filterSinglepageGroup', params.id));

    return {
      rtl,
      handleSubmit,

      props: {
        name: 'file',
        action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
        headers: {
          authorization: 'authorization-text',
        },
        listType: 'picture-card',
        onChange(info) {
          if (info.file.status !== 'uploading') {
            fileList.value = info.fileList;
          }
          if (info.file.status === 'done') {
            message.value.success(`${info.file.name} file uploaded successfully`);
          } else if (info.file.status === 'error') {
            message.value.error(`${info.file.name} file upload failed.`);
          }
        },
      },
      attachment: {
        name: 'file',
        action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
        headers: {
          authorization: 'authorization-text',
        },
        onChange(info) {
          if (info.file.status !== 'uploading') {
            // console.log(info.file, info.fileList);
            fileList2.value = info.fileList;
          }
          if (info.file.status === 'done') {
            message.value.success(`${info.file.name} file uploaded successfully`);
          } else if (info.file.status === 'error') {
            message.value.error(`${info.file.name} file upload failed.`);
          }
        },
      },
      handleChange,
      left,
      chatData,
      me,
      singleContent,
      name,
      inputValue,
      fileList,
      fileList2,
      message,
      moment,
    };
  },
};

export default SingleGroupChat;
</script>
<style scoped>
.left-b .message-box {
  background: #0db3b5 !important;
}

.btn-send {
  background: #0db3b5 !important;
}
.no-data {
  color: #0db3b5;
  padding-left: 5%;
  font-weight: bold;
  font-size: large;
  font-stretch: extra-expanded !important;
}
.spin2 {
  color: white !important;
}
/* @media (max-width:575px){ */

/* } */

.tstamp {
  color: gray;
  font-size: 11px;
  /* margin-left: 2%; */
  /* display: block; */
  float: right !important;
  /* margin-right: 0; */
  /* position: absolute; */
}
.tstamp2 {
  color: gray;
  font-size: 11px;
  /* margin-left: 2%; */
  /* display: block; */
  /* float: right !important; */
  /* margin-right: 0; */
  /* position: absolute; */
}
@media (min-width: 991px) {
  .back-display {
    display: none;
  }
}
</style>
<style>
.atbd-chatbox__single {
  margin-bottom: 4% !important;
}
.ant-card-head-title {
  /* background: rgb(121, 121, 115) !important; */
  width: 100% !important;
}
.loader {
  border: 10px solid #0db0b2;
  border-radius: 50%;
  border-top: 4px solid white;
  width: 60px;
  position: absolute;
  left: 50%;
  top: 35%;
  z-index: 1000;
  height: 60px;
  animation: spin 2s linear infinite;
}
.loaderstyle {
  align-content: center;
  padding-top: 40%;
  padding-left: 40%;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* @media (min-width: 1201px) and (max-width: 1700px) {
  .ant-card-body {
    width: 100%;
  }
} */
</style>
